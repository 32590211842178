.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 12px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.site-layout .site-layout-background {
  background: #fff;
}

#root {
  height: 100%;
}

.ant-layout {
  display: flex;
  width: 100%;
  min-height: 100%;
}
/* 自定义滚动条样式 */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  -webkit-border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.01);
}
::-webkit-scrollbar-track-piece {
  -webkit-border-radius: 5px;
}
/* 自定义滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background: #5555; /* 设置滑块背景色 */
  border-radius: 5px; /* 设置滑块圆角 */
}
/* 设置滚动条的hover和active样式 */
::-webkit-scrollbar-thumb:hover {
  background: #888; /* 设置滑块hover背景色 */
}

::-webkit-scrollbar-thumb:active {
  background: #333; /* 设置滑块active背景色 */
}